import Multimedia from '@/dc-it/models/Multimedia'
import MultipleMultimediaComponent from '../components/inputs/MultipleMultimediaComponent.vue'
import MultipleRelation from './MutipleRelation'

export default class MultipleMultimedia extends MultipleRelation {
  constructor(name, label, value = []) {
    super(name, label, Multimedia, value)
  }

  getComponent = () => MultipleMultimediaComponent;
}
