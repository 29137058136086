import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import Multimedia from '@/dc-it/models/Multimedia'

export default class CommunityMultimedia extends Model {
    endpoint = 'community_multimedia'

    related = ['multimedia']

    fields = [
      new Field('id', 'Código').disabled(),
      new MultipleMultimedia('multimedia', 'Imagen', Multimedia).hide().setLimit(1),
      new Field('url', 'URL de redirección'),
    ]

    clone = () => CommunityMultimedia
}
